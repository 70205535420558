import React from 'react';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import { between } from 'polished';
import { FaInfoCircle, FaLongArrowAltRight } from 'react-icons/fa';

import Page from '../../components/page/page';
import HeaderSection from '../../components/header-section';
import HeaderFront from '../../components/header-front';
import Navigation from '../../components/navigation';
import HeaderHeading from '../../components/header-heading';
import HeaderDescription from '../../components/header-description';
import SEO from '../../components/seo';
import Container from '../../components/container';
import Alert from '../../components/alert';

const StyledHeaderSection = styled(HeaderSection)`
	min-height: ${between('280px', '470px')};
`;

const StyledContainer = styled(Container)`
	text-align: center;

	.emoji {
		font-size: ${between('36px', '50px')};
		margin-right: ${between('16px', '30px')};
	}

	h2 {
		font-size: ${between('30px', '50px')};
		display: inline-block;
	}
`;

class ThankYouPage extends React.Component {
	componentDidMount() {
		if (typeof window === 'undefined' || !window || !window.localStorage) navigate('/profil/');
		if (window.localStorage.getItem('newPostAdded')) window.localStorage.removeItem('newPostAdded');
		else navigate('/profil/');
	};

	render() {
		return (
			<Page>
				<SEO title="Dziękujemy!" description="Pokaż czym się zajmujesz i zdobądź nowych klientów" />
				<StyledHeaderSection hero="/hero-profile.jpg">
					<HeaderFront>
						<Navigation />
						<Container>
							<HeaderHeading>Dziękujemy! 🎉</HeaderHeading>
							<HeaderDescription>Ogłoszenie zostało dodane</HeaderDescription>
						</Container>
					</HeaderFront>
				</StyledHeaderSection>

				<StyledContainer>
					<Alert>
						<FaInfoCircle className="icon" />
						Twoje ogłoszenie zostało dodane, pojawi się na stronie, jak tylko zostanie przez nas zaakceptowane (zazwyczaj w ciągu godziny).<br /><br />
						Prowadzisz wiele usług? <Link to="/dodaj-oferte/">Dodaj kolejne ogłoszenie <FaLongArrowAltRight /></Link>
					</Alert>

					<span className="emoji">🎁</span>
					<h2>Bonus!</h2>
					<p>
						W ramach podziękowania za dodanie ogłoszenia, dostajesz w prezencie <Link to="/cennik/">Pakiet Złoty</Link> za darmo na 60 dni.
					</p>
				</StyledContainer>
			</Page>
		);
	};
};

export default ThankYouPage;
